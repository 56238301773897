// --------------------------------------------------------------------------------
// Adjustments
// --------------------------------------------------------------------------------
body {
  padding-top: 59px;

  // --------------------------------------------------------------------------------
  // Breakingnews
  // --------------------------------------------------------------------------------
  .sticky-top-breakingnews {
    top: 59px;
  }
}

// --------------------------------------------------------------------------------
// Backgrounds
// --------------------------------------------------------------------------------
.bg-image {
  background-image: url("../../img/centos-motif.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-image-center {
  background-position: center;
}

.bg-image-top {
  background-position: top;
}

.bg-image-bottom {
  background-position: bottom;
}

.bg-dots {
  background-image: radial-gradient(#fcfcfc 8px, transparent 8px);
  background-size: 24px 24px;
  background-color: #ffffff;
}

// --------------------------------------------------------------------------------
// Section
// --------------------------------------------------------------------------------
.h-polygon-ltr {
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 80%, 0 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 80%, 0 100%);
}
.h-polygon-rtl {
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 80%);
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 100 80%);
}

// --------------------------------------------------------------------------------
// Backgrounds Gradients
// --------------------------------------------------------------------------------
.bg-gradient-primary {
  background: $primary;
  background: linear-gradient(90deg, rgba(161,79,140,0.2) 35%, rgba(239,167,36,0.1) 100%);
}
.bg-gradient-secondary {
  background: $secondary;
  background: linear-gradient(90deg, rgba(239,167,36,0.2) 35%, rgba(161,79,140,0.1) 100%);
}

// --------------------------------------------------------------------------------
// Text Gradients
// --------------------------------------------------------------------------------
.text-gradient-primary,
.text-gradient-secondary {
  color: transparent;
  background-clip: text;
}
.text-gradient-primary {
  background-image: linear-gradient(90deg, #{$primary} 50%, #{$secondary} 60%);
}
.text-gradient-secondary {
  background-image: linear-gradient(270deg, #{$primary} 50%, #{$secondary} 60%);
}

nav {
  // --------------------------------------------------------------------------------
  // Nav
  // --------------------------------------------------------------------------------
  .navbar-brand {
    .manifestation {
      --bs-border-opacity: 0.5;
    }
  }
}
// --------------------------------------------------------------------------------
// Alerts (p), announcements (div), and breakingnews (div)
// --------------------------------------------------------------------------------
p {
  &.alert {
    padding-left: $spacer * 3;
    background-repeat: no-repeat;
    background-position-y: $spacer * 1.2;
    background-position-x: $spacer;
    border-left-width: 0.5rem;
  }
  &.alert-primary {
    background-image: $alert-primary-icon;
  }
  &.alert-secondary {
    background-image: $alert-secondary-icon;
  }
  &.alert-danger {
    background-image: $alert-danger-icon;
  }
  &.alert-warning {
    background-image: $alert-warning-icon;
  }
  &.alert-info {
    background-image: $alert-info-icon;
  }
  &.alert-light {
    background-image: $alert-light-icon;
  }
  &.alert-dark {
    background-image: $alert-dark-icon;
  }
  &.alert-success {
    background-image: $alert-success-icon;
  }
}

main {
  // --------------------------------------------------------------------------------
  // Navbar and linked headings' correction.
  //
  // By default, when you access page headings from the page table of content
  // (toc) links, the heading is hidden behind the navbar.  This workaround
  // fixes the linked headings visibility issue in the page.
  // --------------------------------------------------------------------------------
  .content > h1,
  .content > h2,
  .content > h3,
  .content > h4,
  .content > h5,
  .content > h6 {
    scroll-margin-top: 80px;
    scroll-margin-bottom: 100px;
    margin-top: 3rem;
  }
  .content > h2 {
    border-bottom: 5px solid $primary;
    padding-bottom: $spacer * 0.5;
    margin-bottom: $spacer * 0.75;
  }
  .content > figure {
    scroll-margin-top: 80px;
    scroll-margin-bottom: 100px;
  }
  .content {
    // --------------------------------------------------------------------------------
    // Highlightjs-copy
    // --------------------------------------------------------------------------------
    code.hljs {
      border-radius: var(--bs-border-radius);
    }
    pre {
      .hljs {
        max-height: 50vh;
        overflow: auto;
      }
      .hljs-copy-button {
        top: 0.5rem;
        right: 0.5rem;
        --hljs-theme-background: var(--bs-primary);
      }
    }
    // --------------------------------------------------------------------------------
    // Tables
    // --------------------------------------------------------------------------------
    div.dataTables_wrapper {
      margin-bottom: $spacer;
      div.dataTables_info {
        padding-top: 0;
      }
    }
    table {
      thead {
        @extend .table-light;
      }
    }
  }
}
