//
// Bootstrap customization
//

// --------------------------------------------------------------------------------
// Color
// --------------------------------------------------------------------------------
$centos-purple: #a14f8c;
$centos-orange: #efa724;
$centos-blue: #262577;
$centos-green: #9ccd2a;

$primary: $centos-purple;
$secondary: $centos-orange;
$dark: #200735;
$success: $centos-green;
$info: $centos-blue;
$warning: $centos-orange;
$danger: #dc3545;
$light: #f8f9fa;

$primary-text-emphasis: shade-color($primary, 60%);
$secondary-text-emphasis: shade-color($secondary, 60%);
$success-text-emphasis: shade-color($success, 60%);
$info-text-emphasis: shade-color($info, 60%);
$warning-text-emphasis: shade-color($warning, 60%);
$light-text-emphasis: shade-color($light, 60%);
$dark-text-emphasis: shade-color($dark, 60%);
$danger-text-emphasis: shade-color($danger, 60%);
$dropdown-dark-bg: $dark;

// --------------------------------------------------------------------------------
// Typography
// --------------------------------------------------------------------------------
$font-family-sans-serif: "Montserrat", sans-serif;
$font-family-monospace: "Source Code Pro", monospace;
$headings-font-weight: 700;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 2.75;


// --------------------------------------------------------------------------------
// Breadcrumb
// --------------------------------------------------------------------------------
$breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8'><path d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'/></svg>");

// --------------------------------------------------------------------------------
// Alerts
// --------------------------------------------------------------------------------
$alert-primary-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 42.333 42.333"><g transform="rotate(45 -180.13 -610.203) scale(1.0529)"><circle cx="388.123" cy="-290.716" r="20.103" style="fill:rgba(#{to-rgb($primary-text-emphasis)},1);stroke:none;stroke-width:1.32292;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:fill markers stroke;stop-color:#{to-rgb($primary-text-emphasis)}"/><path d="M377.366-301.473v6.301l-4.456 4.456 4.456 4.456v6.302h6.302l4.456 4.456 4.456-4.456h6.301v-6.302l4.456-4.456-4.456-4.456v-6.301h-6.301l-4.456-4.456-4.456 4.456zm2.271 4.183 1.913-1.912 3.227 3.227v1.913h-1.912zm-2.163 5.222h4.565l1.352 1.352-1.352 1.353h-4.564zm9.297-9.297h2.705v4.564l-1.352 1.353-1.353-1.353zm.091 7.607 1.261 1.282 1.26-1.283-.015 1.798 1.798-.016-1.282 1.26 1.283 1.261-1.798-.015.016 1.798-1.26-1.282-1.261 1.283.015-1.799-1.798.016 1.282-1.26-1.283-1.26 1.799.015zm-7.225 9.617 3.228-3.228h1.912v1.913l-3.227 3.227zm11.833-11.834 3.228-3.227 1.913 1.912-3.228 3.228h-1.912zm-4.698 11.345 1.352-1.352 1.352 1.352v4.564h-2.704zm6.085-6.085 1.352-1.353h4.564v2.705h-4.564zm-1.387 3.346h1.913l3.228 3.228-1.913 1.912-3.228-3.227z" style="fill:%23fff;fill-opacity:1;stroke-width:9.12808;stroke-linecap:round;paint-order:fill markers stroke;stop-color:%23000"/></g></svg>');
$alert-secondary-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 42.333 42.333"><g transform="rotate(45 -180.13 -610.203) scale(1.0529)"><circle cx="388.123" cy="-290.716" r="20.103" style="fill:rgba(#{to-rgb($secondary-text-emphasis)},1);stroke:none;stroke-width:1.32292;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:fill markers stroke;stop-color:#{to-rgb($secondary-text-emphasis)}"/><path d="M377.366-301.473v6.301l-4.456 4.456 4.456 4.456v6.302h6.302l4.456 4.456 4.456-4.456h6.301v-6.302l4.456-4.456-4.456-4.456v-6.301h-6.301l-4.456-4.456-4.456 4.456zm2.271 4.183 1.913-1.912 3.227 3.227v1.913h-1.912zm-2.163 5.222h4.565l1.352 1.352-1.352 1.353h-4.564zm9.297-9.297h2.705v4.564l-1.352 1.353-1.353-1.353zm.091 7.607 1.261 1.282 1.26-1.283-.015 1.798 1.798-.016-1.282 1.26 1.283 1.261-1.798-.015.016 1.798-1.26-1.282-1.261 1.283.015-1.799-1.798.016 1.282-1.26-1.283-1.26 1.799.015zm-7.225 9.617 3.228-3.228h1.912v1.913l-3.227 3.227zm11.833-11.834 3.228-3.227 1.913 1.912-3.228 3.228h-1.912zm-4.698 11.345 1.352-1.352 1.352 1.352v4.564h-2.704zm6.085-6.085 1.352-1.353h4.564v2.705h-4.564zm-1.387 3.346h1.913l3.228 3.228-1.913 1.912-3.228-3.227z" style="fill:%23fff;fill-opacity:1;stroke-width:9.12808;stroke-linecap:round;paint-order:fill markers stroke;stop-color:%23000"/></g></svg>');
$alert-warning-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1rem" fill="rgba(#{to-rgb($warning-text-emphasis)},1)" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>');
$alert-info-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1rem" fill="rgba(#{to-rgb($info-text-emphasis)},1)" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>');
$alert-danger-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1rem" fill="rgba(#{to-rgb($danger-text-emphasis)},1)" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>');
$alert-success-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1rem" fill="rgba(#{to-rgb($success-text-emphasis)},1)" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>');
$alert-light-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1rem" fill="rgba(#{to-rgb($light-text-emphasis)},1)" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>');
$alert-dark-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1rem" fill="rgba(#{to-rgb($dark-text-emphasis)},1)" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>');

// --------------------------------------------------------------------------------
// Negative margins
// --------------------------------------------------------------------------------
$enable-negative-margins: true;
